import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";

// import errorSvg from "./error.svg";
// const Maintenance = () => (
//   <div className="flex items-center justify-center min-h-screen p-4 bg-gray-100 md:p-10 min-w-screen">
//     <div className="flex flex-col p-4 bg-white shadow md:flex-row md:items-center md:max-w-3xl">
//       <div className="md:w-2/3">
//         <span className="block mb-8 text-4xl leading-none text-gray-800">
//           Estamos em manutenção
//         </span>

//         <p>Estamos fazendo algumas mudanças pra melhorar nossos serviços.</p>
//         <p>Por favor, tente mais tarde.</p>
//       </div>

//       <div className="md:w-1/3">
//         <img src={errorSvg} alt="Erro" />
//       </div>
//     </div>
//   </div>
// );

const ShareApp = React.lazy(() => import("./Share/App"));
const TrackingApp = React.lazy(() => import("./Tracking/App"));

const App = (() => {
  if (
    process.env.NODE_ENV === "development" ||
    window.location.origin === "https://snq-apps.somnoquadro.vercel.app"
  ) {
    switch (window.location.search) {
      case "?share":
        return ShareApp;
      case "?tracking":
        return TrackingApp;

      default:
        return () => (
          <div>
            <a className="p-4" href={window.location.href + "?share"}>
              share
            </a>
            <a className="p-4" href={window.location.href + "?tracking"}>
              tracking
            </a>
          </div>
        );
    }
  }
  switch (window.location.host) {
    case "share.somnoquadro.com.br":
      return ShareApp;
    case "tracking.somnoquadro.com.br":
      return TrackingApp;
    default:
      return () => null;
  }
})();



ReactDOM.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
